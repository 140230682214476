import classnames from 'classnames';
import { RichTextData } from 'containers/ReactionBar/Comments';
import { CommentFullFragment, CommentPartsFragment } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import Link from 'next/link';
import { useState } from 'react';
import { AvatarLink, Button, LinkText, RelativeDateShort } from 'ui/generic';
import { ReplyIcon } from 'ui/icons';
import { MentionSuggestionType, RichTextEditor } from 'ui/specific/richText/RichTextEditor';
import { RichTextViewer } from 'ui/specific/richText/RichTextViewer';
import styles from './Comment.module.scss';
import { CommentReactionsBar } from './CommentReactionsBar';

type CommentProps = {
  comment: CommentFullFragment;
  deleteComment: (comment: CommentPartsFragment) => Promise<void>;
  getSuggestions: (query: string) => Promise<MentionSuggestionType>;
  onSubmitEdit: (
    commentId: string,
    data: { text: string; textJson: Record<string, unknown> }
  ) => Promise<void>;
  onLike: (commentId: string) => void;
  isLoading?: boolean;
  avatarSticker?: JSX.Element;
  likeCount: number;
  isLiked: boolean;
  likeLoading?: boolean;
  isReply?: boolean;
  toggleReply: (comment: CommentPartsFragment) => void;
  isHighlighted?: boolean;
  setContentFlagModal?: (val?: any) => void;
};

export const Comment = ({
  comment,
  deleteComment,
  getSuggestions,
  onSubmitEdit,
  onLike,
  isLoading,
  avatarSticker,
  likeCount,
  isLiked,
  likeLoading,
  isReply,
  toggleReply,
  isHighlighted,
  setContentFlagModal,
}: CommentProps): JSX.Element | null => {
  const { isSignedIn, profile } = useAuthContext();
  const [editMode, setEditMode] = useState(false);
  const [commentData, setCommentData] = useState<RichTextData | null>(null);

  const handleSubmitEdit = async () => {
    if (commentData)
      await onSubmitEdit(comment.id as string, {
        text: commentData.text,
        textJson: commentData.textJson,
      });
    setEditMode(false);
  };
  const handleCancelEdit = () => {
    setEditMode(false);
    setCommentData(null);
  };

  // Don't show the deleted message on top-level-comments, if there are no replies
  if (comment.deleted && !isReply && comment.replies && comment.replies.length === 0) return null;

  if (comment.deleted) {
    return (
      <div className={classnames(styles.deleted, { [styles.isReply]: isReply })}>
        <div className={styles.deletedText}>This comment has been deleted</div>
      </div>
    );
  }

  if (!comment.profile || !comment.text) return null;

  return (
    <div
      className={classnames(styles.container, {
        [styles.isReply]: isReply,
        [styles.isHighlighted]: isHighlighted,
      })}
    >
      <div className={styles.left}>
        {isReply && <ReplyIcon />}
        <AvatarLink avatarSticker={avatarSticker} profile={comment.profile} width={isReply ? 21 : 30} />
      </div>
      <div className={styles.right}>
        <div className={styles.header}>
          <span className={styles.user}>
            <Link href="/[handle]" as={`/${comment.profile.handle}`}>
              <a className={styles.name}>{comment.profile.name}</a>
            </Link>
            <span className={styles.handle}>@{comment.profile.handle}</span>
          </span>
          <span className={styles.date}>
            <RelativeDateShort date={comment.createdAt} />
          </span>
        </div>
        <div className={styles.content}>
          {editMode ? (
            <RichTextEditor
              getSuggestions={getSuggestions}
              placeholder="Share your thoughts..."
              setValue={(value: RichTextData) => setCommentData(value)}
              submitted={isLoading}
              content={commentData ? commentData.textJson : comment.json || comment.text}
            />
          ) : comment.json ? (
            <RichTextViewer content={comment.json} />
          ) : (
            <LinkText accent={true}>{comment.text || ''}</LinkText>
          )}
        </div>

        {editMode ? (
          <div className="flex mt-2">
            <Button disabled={isLoading} className="mr-2" mini variant="tertiary" onClick={handleCancelEdit}>
              Cancel
            </Button>

            <Button
              mini
              type="submit"
              variant="focus"
              disabled={isLoading || !commentData}
              onClick={handleSubmitEdit}
            >
              Save
            </Button>
          </div>
        ) : (
          <CommentReactionsBar
            comment={comment}
            deleteComment={deleteComment}
            setEditMode={setEditMode}
            handleLike={(c) => onLike(c.id!)}
            toggleReply={toggleReply}
            likeCount={likeCount}
            isLiked={isLiked}
            likeLoading={likeLoading ?? false}
            isSignedIn={isSignedIn}
            canEdit={comment.profileId === profile?.id}
            canDelete={comment.profileId === profile?.id}
            setContentFlagModal={setContentFlagModal}
          />
        )}
      </div>
    </div>
  );
};
