import { PureQueryOptions } from '@apollo/client';
import { JSONContent } from '@tiptap/react';
import { CommentInput } from 'components/reaction/CommentInput';
import { CommentsContainer } from 'components/reaction/CommentsContainer';
import { MoreCommentsButton } from 'components/reaction/MoreCommentsButton';
import {
  CommentFullFragment,
  CommentPartsFragment,
  IsSubscribedToActivityUpdatesDocument,
  PostDocument,
  ReactionTarget,
  useAddCommentMutation,
  useCommentsByTargetLazyQuery,
  useRemoveCommentMutation,
  useSearchProfilesLazyQuery,
  useUpdateCommentMutation,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import cloneDeep from 'lodash.clonedeep';
import { useMemo, useState } from 'react';
import { ActivityObjectType } from 'types/activity';
import { notEmpty } from 'utils/typeGuards';
import CommentContainer from './Comment';

export type RichTextData = { text: string; textJson: JSONContent; hideLinkPreview?: boolean };

type CommentsProps = {
  targetId: string;
  targetType: ReactionTarget;
  comments: CommentFullFragment[];
  commentCount: number;
  refetchReactionProfiles?: () => Promise<void>;

  // in order to differentiate between different activities
  // for the pupose of mixpanel tracking
  trackingTarget: ActivityObjectType;
};

/**
 * @todo: refactor the state management inside this component
 * to use apollo and its cache just like everything else.
 * Historically, the reason was that we were limited by stream,
 * but now reactions are in our own database and queries
 */
export const Comments = ({
  targetId,
  targetType,
  comments: preloadedComments,
  commentCount,
  refetchReactionProfiles,
  trackingTarget,
}: CommentsProps): JSX.Element | null => {
  const { trackEvent } = useMixpanel();
  const { profile, isSignedIn } = useAuthContext();
  const [comments, setComments] = useState<CommentFullFragment[]>(preloadedComments || []);
  const [replyToComment, setReplyToComment] = useState<CommentPartsFragment | null>(null);
  const [addCommentMutation, { loading: commentLoading }] = useAddCommentMutation();
  const [updateCommentMutation, { loading: updatingComment }] = useUpdateCommentMutation();
  const [removeCommentMutation, { loading: deletingComment }] = useRemoveCommentMutation();
  const [loadComments] = useCommentsByTargetLazyQuery();
  const [searchProfiles] = useSearchProfilesLazyQuery();

  const [commentsLoading, setCommentsLoading] = useState(false);

  const numberShown = useMemo(() => {
    let count: number = comments?.length || 0;
    comments.forEach((comment) => {
      count += comment.replies?.length || 0;
    });
    return count;
  }, [comments]);

  const _updateComment = (newComment: CommentFullFragment) => {
    if (
      newComment.targetType === ReactionTarget.Activity ||
      newComment.targetType === ReactionTarget.ClubPost
    ) {
      setComments((prev) =>
        prev.map((c) => (c.id === newComment.id ? { ...newComment, replies: c.replies } : c))
      );
    } else if (newComment.targetType === ReactionTarget.Comment) {
      setComments((prev) => {
        return prev.map((c) =>
          c.id === newComment.targetId
            ? {
                ...c,
                replies: [...(c.replies?.map((r) => (r.id === newComment.id ? newComment : r)) || [])],
              }
            : c
        );
      });
    }
  };

  const deleteComment = async (comment: CommentPartsFragment) => {
    if (!profile || !comment) return;
    const areYouSure = confirm('This is permanent. Are you sure you want to delete your comment?');
    if (areYouSure) {
      const { data } = await removeCommentMutation({
        variables: { commentId: comment.id },
        refetchQueries: () => {
          const queries: Array<PureQueryOptions> = [];
          if (targetType === ReactionTarget.ClubPost) {
            queries.push({
              query: PostDocument,
              variables: { postId: targetId },
            });
          }
          return queries;
        },
      });
      if (data) {
        _updateComment(data.removeComment);
      }
      refetchReactionProfiles?.();
    }
  };

  const updateComment = async (commentId: string, data: RichTextData) => {
    if (!profile || !commentId) return;
    const result = await updateCommentMutation({
      variables: { commentId, text: data.text, json: data.textJson },
    });
    const updatedComment = result.data?.updateComment;
    if (!updatedComment) return;
    _updateComment(updatedComment);
  };

  const getMoreComments = async () => {
    if (!comments || commentsLoading || comments.length < 1) {
      return null;
    }
    setCommentsLoading(true);
    const { data } = await loadComments({
      variables: {
        targetId,
        targetType,
        limit: 3,
        offset: comments.length,
      },
    });
    if (data) {
      setComments([...comments, ...data.commentsByTarget]);
    }
    setCommentsLoading(false);
  };

  const handleCommentSubmit = async (data: { text: string; json: Record<string, unknown> }) => {
    if (data.text) {
      const result = await addCommentMutation({
        variables: {
          targetId,
          targetType,
          text: data.text,
          json: data.json,
        },
        refetchQueries: () => {
          const queries: Array<PureQueryOptions> = [];
          if (targetType === ReactionTarget.ClubPost) {
            queries.push({
              query: PostDocument,
              variables: { postId: targetId },
            });
          }
          if (targetType === ReactionTarget.Activity) {
            queries.push({
              query: IsSubscribedToActivityUpdatesDocument,
              variables: { activityId: targetId },
            });
          }
          return queries;
        },
      });
      refetchReactionProfiles?.();

      if (result.data?.addComment) {
        const comment = result.data.addComment;
        if (comment) {
          setComments([comment, ...comments]);
        }
      }
      trackEvent('commented', { targetType, object: trackingTarget });
    }
  };

  const handleReplySubmit = async (
    targetId: string,
    data: { text: string; json: Record<string, unknown> }
  ) => {
    if (data.text) {
      const result = await addCommentMutation({
        variables: {
          targetId,
          targetType: ReactionTarget.Comment,
          text: data.text,
          json: data.json,
        },
      });

      if (result.data?.addComment) {
        const comment = result.data.addComment;
        if (comment) {
          setComments((prev) => {
            const comments = cloneDeep(prev);
            const targetComment = comments.find((c) => c.id === targetId);
            if (targetComment) {
              targetComment.replies = targetComment.replies || [];
              targetComment.replies.unshift(comment);
            }
            return comments;
          });
        }
      }
      trackEvent('commented', { targetType: ReactionTarget.Comment, object: ReactionTarget.Comment });
    }
  };

  const getMentionSuggestions = async (query: string) => {
    const searchQuery = query.toLowerCase();
    if (!searchQuery) return [];
    const usersInThread =
      comments
        .map((comment) => comment.profile)
        .filter(notEmpty)
        .filter(
          (profile) =>
            profile.handle.toLowerCase().includes(searchQuery) ||
            profile.name.toLowerCase().includes(searchQuery)
        ) || [];
    const { data } = await searchProfiles({ variables: { query: searchQuery } });
    if (!data) {
      return usersInThread;
    } else {
      return [...usersInThread, ...data.searchProfiles].filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
    }
  };

  return (
    <CommentsContainer>
      {numberShown < commentCount && <MoreCommentsButton onClick={getMoreComments} />}
      {comments &&
        comments
          .slice()
          .reverse()
          .map((comment) => {
            const ids = [comment.id, ...(comment.replies?.map((r) => r.id) || [])];
            return (
              <CommentContainer
                key={comment.id}
                deleteComment={deleteComment}
                comment={comment}
                getSuggestions={getMentionSuggestions}
                onSubmitEdit={updateComment}
                isLoading={updatingComment || deletingComment}
                submitReply={handleReplySubmit}
                showReplyInput={!!replyToComment && ids.includes(replyToComment.id)}
                setReplyToComment={setReplyToComment}
                replyMentions={
                  replyToComment?.profile && replyToComment.profile.id !== profile?.id
                    ? [replyToComment.profile]
                    : []
                }
                replies={comment.replies
                  ?.slice()
                  .reverse()
                  .map((reply) => (
                    <CommentContainer
                      key={reply.id}
                      deleteComment={deleteComment}
                      comment={reply}
                      getSuggestions={getMentionSuggestions}
                      onSubmitEdit={updateComment}
                      isLoading={updatingComment || deletingComment}
                      isReply
                      setReplyToComment={setReplyToComment}
                    />
                  ))}
              />
            );
          })}
      {isSignedIn && profile && (
        <CommentInput
          getSuggestions={getMentionSuggestions}
          profile={profile}
          onSubmit={handleCommentSubmit}
          isLoading={commentLoading}
        />
      )}
    </CommentsContainer>
  );
};
