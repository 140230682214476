import { DropdownMobileAndDesktop } from 'components/basics/Dropdowns/DropdownMobileAndDesktop';
import { CommentPartsFragment } from 'generated/graphql';
import { useMemo, useState } from 'react';
import { CommentIcon, DeleteIcon, EditIcon, FlagIcon } from 'ui/icons';
import { ActionItemType, ActionList } from 'ui/specific/actionList';
import styles from './CommentReactionsBar.module.scss';
import { LikeIcon } from './LikeIcon';
import OptionsButton from './OptionsButton';
import ReactionButton from './ReactionButton';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 600;

type Props = {
  comment: CommentPartsFragment;
  deleteComment: (comment: CommentPartsFragment) => void;
  setEditMode: (val: boolean) => void;
  toggleReply: (comment: CommentPartsFragment) => void;
  handleLike: (comment: CommentPartsFragment) => void;
  likeCount: number;
  isLiked: boolean;
  likeLoading?: boolean;
  isSignedIn?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  setContentFlagModal?: (val?: any) => void;
};

export const CommentReactionsBar = ({
  comment,
  deleteComment,
  setEditMode,
  toggleReply,
  handleLike,
  likeCount = 0,
  isLiked,
  likeLoading,
  isSignedIn,
  canEdit,
  canDelete,
  setContentFlagModal,
}: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  const actionItems = useMemo(() => {
    const result: ActionItemType[] = [];

    if (canEdit) {
      result.push({ icon: <EditIcon />, title: 'Edit', onClick: () => setEditMode(true) });
    }

    if (canDelete) {
      result.push({ icon: <DeleteIcon />, title: 'Delete', onClick: () => deleteComment(comment) });
    }

    if (isSignedIn && setContentFlagModal) {
      if (result.length > 0) result.push({ divider: 'full' });
      result.push({ icon: <FlagIcon />, title: 'Report', onClick: setContentFlagModal });
    }

    return result;
  }, [canEdit, canDelete, comment, deleteComment, isSignedIn, setContentFlagModal]);

  return (
    <div className={styles.container}>
      <div className="flex">
        <ReactionButton
          onClick={() => handleLike(comment)}
          disabled={!isSignedIn || likeLoading}
          icon={<LikeIcon isLiked={isLiked} />}
        >
          {likeCount > 0 && likeCount}
        </ReactionButton>
        <ReactionButton onClick={() => toggleReply(comment)} icon={<CommentIcon />} />
      </div>

      {actionItems.length > 0 && (
        <DropdownMobileAndDesktop
          button={<OptionsButton iconSize="16" />}
          isOpen={isOpen}
          setOpen={setOpen}
          isMobile={isMobile}
        >
          <ActionList items={actionItems} />
        </DropdownMobileAndDesktop>
      )}
    </div>
  );
};
