import { NetworkStatus, PureQueryOptions } from '@apollo/client';
import { useClubStatsLoader } from 'dataloaders/clubStatsLoader';
import {
  ClubMemberRole,
  ClubMembershipPartsFragment,
  ClubPartsFragment,
  GetClubMembershipsDocument,
  GetClubModeratorsDocument,
  GetMyClubStatusDocument,
  GetMyMembershipByClubIdDocument,
  ProfilePartsFragment,
  useChangeMemberRoleMutation,
  useClubQuery,
  useGetClubMembershipsQuery,
  useGetClubModeratorsQuery,
  useGetMyMembershipByClubIdQuery,
  useJoinClubMutation,
  useKickMemberMutation,
  useLeaveClubMutation,
} from 'generated/graphql';
import React, { FC, useContext, useMemo } from 'react';
import { noop, noopAsync } from 'utils/noop';
import { useAuthContext } from './useAuth';
import { useClubBehavior } from './useClubBehavior';

export interface ClubMemberProfile extends ClubMembershipPartsFragment {
  profile: ProfilePartsFragment;
}

type ClubContextValues = {
  getRole: (profileId: string) => ClubMemberRole | void;
  members: ClubMemberProfile[];
  moderators: ClubMemberProfile[];
  fetchMoreMembers: () => void;
  joinClub: () => Promise<void>;
  leaveClub: () => Promise<void>;
  kickMember: (profileId: string) => Promise<void>;
  changeMemberRole: (profileId: string, role: ClubMemberRole) => Promise<void>;
  loading: boolean;
  myRole: ClubMemberRole | null;
  club?: ClubPartsFragment;
};

export const clubRefetchQueries = (clubId: string, offset: number) => (): Array<PureQueryOptions> =>
  [
    { query: GetMyClubStatusDocument },
    { query: GetMyMembershipByClubIdDocument, variables: { clubId } },
    { query: GetClubMembershipsDocument, variables: { clubId, limit: 10, offset: offset } },
    { query: GetClubModeratorsDocument, variables: { clubId } },
  ];

const defaultContextValues: ClubContextValues = {
  getRole: noop,
  members: [],
  moderators: [],
  fetchMoreMembers: noop,
  joinClub: noopAsync,
  leaveClub: noopAsync,
  kickMember: noopAsync,
  changeMemberRole: noopAsync,
  loading: false,
  myRole: null,
};

export const ClubContext = React.createContext<ClubContextValues>(defaultContextValues);

type ProviderProps = {
  clubId: string;
  handle: string;
};

export const ClubContextProvider: FC<ProviderProps> = ({ children, clubId, handle }) => {
  // State
  const { isSignedIn } = useAuthContext();
  const [changeRole] = useChangeMemberRoleMutation();
  const [kickMemberOut] = useKickMemberMutation();
  const [joinClubMutation, { loading: joiningClub }] = useJoinClubMutation();
  const [leaveClubMutation, { loading: leavingClub }] = useLeaveClubMutation();
  const { refetch: refetchClubStats } = useClubStatsLoader(clubId);

  // Todo: paginate this query
  const { data: clubModerators, loading: loadingModerators } = useGetClubModeratorsQuery({
    variables: { clubId },
  });

  const { data: club } = useClubQuery({ variables: { handle } });

  const { canSeeClub } = useClubBehavior({ clubId });

  const {
    data: myMembershipData,
    loading: loadingMyMembership,
    networkStatus,
  } = useGetMyMembershipByClubIdQuery({
    variables: { clubId },
    skip: !isSignedIn,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: clubMembers,
    fetchMore: fetchMembers,
    loading: loadingMembers,
  } = useGetClubMembershipsQuery({
    variables: { clubId: clubId, limit: 20, offset: 0 },
    skip: !canSeeClub,
    fetchPolicy: 'cache-and-network',
  });

  const myMembership = myMembershipData?.getMyMembershipByClubId?.role;

  const offset = useMemo(() => clubMembers?.getClubMemberships?.length || 0, [clubMembers]);

  const joinClub = async () => {
    await joinClubMutation({
      variables: { clubId },
      awaitRefetchQueries: true,
      refetchQueries: clubRefetchQueries(clubId, 0),
      onCompleted: () => refetchClubStats(),
    });
  };

  const leaveClub = async () => {
    await leaveClubMutation({
      variables: { clubId },
      refetchQueries: clubRefetchQueries(clubId, 0),
      onCompleted: () => refetchClubStats(),
    });
  };

  const kickMember = async (profileId: string) => {
    await kickMemberOut({
      variables: { clubId: clubId, profileId: profileId },
      awaitRefetchQueries: true,
      refetchQueries: clubRefetchQueries(clubId, 0),
      onCompleted: () => refetchClubStats(),
    });
  };

  const changeMemberRole = async (profileId: string, role: ClubMemberRole) => {
    await changeRole({
      variables: { clubId: clubId, profileId: profileId, role: role },
      awaitRefetchQueries: true,
      refetchQueries: clubRefetchQueries(clubId, 0),
    });
  };

  const fetchMoreMembers = async () => {
    if (!clubMembers || loadingMembers) return null;
    fetchMembers({
      variables: {
        offset: offset,
        limit: 20,
      },
    });
  };

  const getRole = (profileId: string): ClubMemberRole | undefined => {
    const modRole = clubModerators?.getClubModerators.find((mod) => mod.profileId === profileId)?.role;
    return modRole;
  };

  return (
    <ClubContext.Provider
      value={{
        getRole,
        members: clubMembers?.getClubMemberships || [],
        moderators: clubModerators?.getClubModerators || [],
        joinClub,
        leaveClub,
        kickMember,
        changeMemberRole,
        fetchMoreMembers,
        loading:
          loadingMembers ||
          loadingModerators ||
          joiningClub ||
          leavingClub ||
          loadingMyMembership ||
          networkStatus === NetworkStatus.refetch,
        myRole: myMembership || null,
        club: club?.clubByHandle || undefined,
      }}
    >
      {children}
    </ClubContext.Provider>
  );
};

export const useClubContext = (): ClubContextValues => {
  try {
    const clubContext = useContext(ClubContext);
    return clubContext;
  } catch {
    return defaultContextValues;
  }
};

export default useClubContext;
