import classNames from 'classnames';
import UserGroup from 'components/basics/UserGroup';
import { ProfilePartsFragment, ReactionTarget } from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { ButtonSimple } from 'ui/generic';
import styles from './ReactionCountBar.module.scss';

type Props = {
  targetId: string;
  targetType: ReactionTarget;
  likeCount: number;
  commentCount: number;
  likeProfiles: ProfilePartsFragment[];
  commentProfiles: ProfilePartsFragment[];
  setCommentsOpen?: () => void;
};

const ReactionCountBar = ({
  targetId,
  targetType,
  likeCount,
  commentCount,
  likeProfiles,
  commentProfiles,
  setCommentsOpen,
}: Props): JSX.Element | null => {
  const { setLikesModal } = useGlobalModalContext();

  if (likeCount === 0 && commentCount === 0) return null;

  return (
    <>
      <div className={styles.container}>
        {likeProfiles.length > 0 && likeCount > 0 && (
          <div className={classNames(styles.group, 'mr-6')}>
            <ButtonSimple
              variant="faded"
              onClick={(e) => {
                e.preventDefault();
                setLikesModal({ targetId, targetType });
              }}
            >
              <UserGroup avatarSize={21} users={likeProfiles} />{' '}
              <span className={styles.text}>{`${likeCount} like${likeCount > 1 ? 's' : ''}`}</span>
            </ButtonSimple>
          </div>
        )}
        {commentProfiles && commentProfiles.length > 0 && commentCount > 0 && (
          <div className={styles.group}>
            <ButtonSimple
              variant="faded"
              onClick={() => {
                if (setCommentsOpen) setCommentsOpen();
              }}
            >
              <UserGroup avatarSize={21} users={commentProfiles} />{' '}
              <span className={styles.text}>{`${commentCount} comment${commentCount > 1 ? 's' : ''}`}</span>
            </ButtonSimple>
          </div>
        )}
      </div>
    </>
  );
};

export default ReactionCountBar;
