import classNames from 'classnames';
import { useReactionProfilesLoader } from 'dataloaders/reactionProfilesLoader';
import { CommentFullFragment, ReactionTarget } from 'generated/graphql';
import { ReactNode, useState } from 'react';
import { ActivityObjectType } from 'types/activity';
import { Comments } from './Comments';
import { ReactionBar } from './ReactionBar';
import styles from './ReactionBar.module.scss';
import ReactionCountBar from './ReactionCountBar';

type Props = {
  targetId: string;
  targetType: ReactionTarget;
  comments: CommentFullFragment[];
  commentCount: number;
  likeCount: number;
  shareButton?: ReactNode;
  optionsButton?: ReactNode;
  initialHideComments?: boolean;
  alwaysShowComments?: boolean;

  // in order to differentiate between different activities
  // for the pupose of mixpanel tracking
  trackingTarget: ActivityObjectType;
};

export const ExtendedReactionBar = ({
  targetId,
  targetType,
  comments,
  commentCount,
  likeCount,
  shareButton,
  optionsButton,
  initialHideComments,
  alwaysShowComments,
  trackingTarget,
}: Props): JSX.Element | null => {
  const { data: reactionProfilesData, refetch: refetchReactionProfiles } = useReactionProfilesLoader({
    targetId,
    targetType,
  });

  const [commentsOpen, setCommentsOpen] = useState(
    alwaysShowComments || (!initialHideComments && commentCount > 0)
  );

  if (!comments) return null;

  const toggleComments = () =>
    setCommentsOpen((state) => {
      if (alwaysShowComments) return true;
      return !state;
    });

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.commentsOpen]: commentsOpen,
        })}
      >
        <ReactionBar
          targetId={targetId}
          targetType={targetType}
          commentCount={commentCount}
          likeCount={likeCount}
          refetchReactionProfiles={refetchReactionProfiles}
          handleCommentClick={toggleComments}
          shareButton={shareButton}
          optionsButton={optionsButton}
          trackingTarget={trackingTarget}
        />

        {reactionProfilesData && (
          <ReactionCountBar
            commentCount={commentCount}
            likeCount={likeCount}
            setCommentsOpen={toggleComments}
            {...reactionProfilesData}
          />
        )}
      </div>

      {commentsOpen && (
        <Comments
          targetId={targetId}
          targetType={targetType}
          comments={comments}
          commentCount={commentCount}
          refetchReactionProfiles={refetchReactionProfiles}
          trackingTarget={trackingTarget}
        />
      )}
    </>
  );
};
