import { PatronBadgeSmall } from 'components/badges/PatronBadge';
import { Comment } from 'components/reaction/Comment';
import { CommentInput } from 'components/reaction/CommentInput';
import ClubModeratorSticker from 'containers/Clubs/ClubModeratorSticker';
import { useLikeCountLoader } from 'dataloaders/likeCountLoader';
import { useReactionProfilesLoader } from 'dataloaders/reactionProfilesLoader';
import {
  ClubMemberRole,
  CommentPartsFragment,
  ContentFlagTarget,
  ProfilePartsFragment,
  ReactionTarget,
  useLikeTargetMutation,
  useUnlikeTargetMutation,
} from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import { useClubContext } from 'hooks/useClubContext';
import { useIsSupporter } from 'hooks/useIsSupporter';
import useMixpanel from 'hooks/useMixpanel';
import { Dispatch, ReactNode, useMemo, useState } from 'react';
import { MentionSuggestionType } from 'ui/specific/richText/RichTextEditor';

const getHighlightCommentId = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search.replace('?', '')).get('highlight-comment');
  }
};

type CommentProps = {
  comment: CommentPartsFragment;
  deleteComment: (comment: CommentPartsFragment) => Promise<void>;
  getSuggestions: (query: string) => Promise<MentionSuggestionType>;
  isLoading?: boolean;
  avatarSticker?: JSX.Element;
  onSubmitEdit: (commentId: string, data: { text: string; textJson: Record<string, any> }) => Promise<void>;
  submitReply?: (commentId: string, data: { text: string; json: Record<string, unknown> }) => Promise<void>;
  replies?: ReactNode;
  isReply?: boolean;
  showReplyInput?: boolean;
  setReplyToComment: Dispatch<React.SetStateAction<CommentPartsFragment | null>>;
  replyMentions?: ProfilePartsFragment[];
};

export const CommentContainer = ({
  comment,
  deleteComment,
  getSuggestions,
  isLoading,
  onSubmitEdit,
  replies = null,
  submitReply,
  isReply,
  showReplyInput = false,
  setReplyToComment,
  replyMentions,
}: CommentProps) => {
  const { getRole } = useClubContext();
  const { profile } = useAuthContext();
  const { setContentFlagModal } = useGlobalModalContext();
  const { trackEvent } = useMixpanel();

  const isSupporter = useIsSupporter({ profileId: comment.profileId || undefined });

  const [likeLoading, setLikeLoading] = useState(false);

  const [likeTarget] = useLikeTargetMutation();
  const [unlikeTarget] = useUnlikeTargetMutation();

  const { refetch: refetchReactionProfiles } = useReactionProfilesLoader({
    targetId: comment.id || '',
    targetType: ReactionTarget.Comment,
  });
  const { data: likeData, setData: setLikeData } = useLikeCountLoader({
    targetId: comment.id || '',
    targetType: ReactionTarget.Comment,
  });
  const memberRole = getRole(comment.profileId || '');

  const toggleReply = (comment: CommentPartsFragment) => {
    setReplyToComment((current) => {
      if (current && current.id === comment.id) return null;
      return comment;
    });
  };

  const handleLike = async (commentId: string) => {
    if (!likeData) return;
    setLikeLoading(true);
    const variables = { targetId: commentId, targetType: ReactionTarget.Comment };
    if (likeData.isLiked) {
      const { data } = await unlikeTarget({ variables });
      if (data?.unlikeTarget?.success) {
        setLikeData((d) => (d ? { ...d, isLiked: false, count: d.count - 1 } : null));
      }
      trackEvent('unliked', { targetType: ReactionTarget.Comment, object: ReactionTarget.Comment });
    } else {
      const { data } = await likeTarget({ variables });
      if (data?.likeTarget?.success) {
        setLikeData((d) => (d ? { ...d, isLiked: true, count: d.count + 1 } : null));
      }
      trackEvent('liked', { targetType: ReactionTarget.Comment, object: ReactionTarget.Comment });
    }

    refetchReactionProfiles();
    setLikeLoading(false);
  };

  const badge = useMemo(() => {
    if (memberRole && memberRole !== ClubMemberRole.Member) {
      return <ClubModeratorSticker small role={memberRole} />;
    }
    if (isSupporter) {
      return <PatronBadgeSmall size={14} />;
    }
  }, [memberRole, isSupporter]);

  return (
    <>
      <Comment
        key={comment.id}
        deleteComment={deleteComment}
        comment={comment}
        getSuggestions={getSuggestions}
        onSubmitEdit={onSubmitEdit}
        isLoading={isLoading}
        avatarSticker={badge}
        onLike={handleLike}
        likeCount={likeData?.count || 0}
        isLiked={likeData?.isLiked ?? false}
        likeLoading={likeLoading}
        toggleReply={toggleReply}
        isReply={isReply}
        isHighlighted={getHighlightCommentId() === comment.id}
        setContentFlagModal={() =>
          setContentFlagModal({
            targetId: comment.id,
            targetType: ContentFlagTarget.Comment,
            snapshot: JSON.stringify(comment),
          })
        }
      />
      {replies}
      {profile && submitReply && showReplyInput && (
        <div className="ml-11">
          <CommentInput
            getSuggestions={getSuggestions}
            profile={profile}
            onSubmit={async (data) => {
              await submitReply(comment.id, data);
              setReplyToComment(null);
            }}
            replyMentions={replyMentions}
            isLoading={isLoading}
            autoFocus
            isReply
          />
        </div>
      )}
    </>
  );
};

export default CommentContainer;
