import DataLoader from 'dataloader';
import {
  LikeCount,
  LikeCountInput,
  LikeCountsDocument,
  LikeCountsQuery,
  LikeCountsQueryVariables,
  Maybe,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';

export const likeCountLoader = new DataLoader(
  async (keys: readonly LikeCountInput[]): Promise<Maybe<LikeCount>[]> => {
    const client = getApolloClient();

    const { data } = await client.query<LikeCountsQuery, LikeCountsQueryVariables>({
      query: LikeCountsDocument,
      variables: { pairs: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.likeCounts;
  },
  {
    batch: true,
    cache: true,
    cacheKeyFn: (key: LikeCountInput): string => `likeCount:${key.targetId}:${key.targetType}`,
  }
);

export function useLikeCountLoader({ targetId, targetType }: LikeCountInput) {
  const [data, setData] = useState<LikeCount | null>(null);

  useEffect(() => {
    if (!targetId || !targetType) return;
    likeCountLoader.load({ targetId, targetType }).then((s) => {
      setData(s);
    });
  }, [targetId, targetType]);

  return {
    data,
    setData,
    refetch: async () => {
      if (!targetId || !targetType) return;
      likeCountLoader.clear({ targetId, targetType });
      const data = await likeCountLoader.load({ targetId, targetType });
      setData(data);
    },
  };
}
