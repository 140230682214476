import DataLoader from 'dataloader';
import {
  Maybe,
  ReactionProfiles,
  ReactionProfilesDocument,
  ReactionProfilesInput,
  ReactionProfilesQuery,
  ReactionProfilesQueryVariables,
} from 'generated/graphql';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { useEffect } from 'react';
import getApolloClient from 'utils/getApolloClient';

export const reactionProfilesLoader = new DataLoader(
  async (keys: readonly ReactionProfilesInput[]): Promise<Maybe<ReactionProfiles>[]> => {
    const client = getApolloClient();

    const { data } = await client.query<ReactionProfilesQuery, ReactionProfilesQueryVariables>({
      query: ReactionProfilesDocument,
      variables: { pairs: [...keys] },
      fetchPolicy: 'no-cache',
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.reactionProfiles;
  },
  {
    batch: true,
    cache: true,
    cacheKeyFn: (key: ReactionProfilesInput): string => `ReactionProfiles:${key.targetId}:${key.targetType}`,
  }
);

export function useReactionProfilesLoader({ targetId, targetType }: ReactionProfilesInput) {
  const [data, setData] = useSessionStorage<ReactionProfiles | null>(
    `ReactionProfiles:${targetId}:${targetType}`,
    null
  );

  useEffect(() => {
    if (!targetId || !targetType) return;
    reactionProfilesLoader.load({ targetId, targetType }).then((s) => {
      setData(s);
    });
  }, [targetId, targetType]);

  return {
    data,
    setData,
    refetch: async () => {
      if (!targetId || !targetType) return;
      reactionProfilesLoader.clear({ targetId, targetType });
      const data = await reactionProfilesLoader.load({ targetId, targetType });
      setData(data);
    },
  };
}
