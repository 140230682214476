import { PureQueryOptions } from '@apollo/client';
import { LikeIcon } from 'components/reaction/LikeIcon';
import ReactionButton from 'components/reaction/ReactionButton';
import { useLikeCountLoader } from 'dataloaders/likeCountLoader';
import {
  PostDocument,
  ReactionTarget,
  useLikeTargetMutation,
  useUnlikeTargetMutation,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import { MouseEvent, ReactNode } from 'react';
import { ActivityObjectType } from 'types/activity';
import { CommentIcon } from 'ui/icons';
import styles from './ReactionBar.module.scss';

type Props = {
  targetId: string;
  targetType: ReactionTarget;
  commentCount: number;
  likeCount: number;
  refetchReactionProfiles: () => Promise<void>;
  handleCommentClick?: () => void;
  shareButton?: ReactNode;
  optionsButton?: ReactNode;

  // in order to differentiate between different activities
  // for the pupose of mixpanel tracking
  trackingTarget: ActivityObjectType;
};

export const ReactionBar = ({
  targetId,
  targetType,
  shareButton,
  optionsButton,
  handleCommentClick,
  refetchReactionProfiles,
  trackingTarget,
}: Props) => {
  const { trackEvent } = useMixpanel();
  const { isSignedIn } = useAuthContext();
  const [likeTarget, { loading: likeLoading }] = useLikeTargetMutation();
  const [unlikeTarget, { loading: unlikeLoading }] = useUnlikeTargetMutation();

  const { data: likeData, setData: setLikeData } = useLikeCountLoader({
    targetId,
    targetType,
  });

  const handleLikeClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (likeLoading || unlikeLoading || !likeData) return;
    const refetchQueries: Array<PureQueryOptions> = [];
    if (targetType === ReactionTarget.ClubPost) {
      refetchQueries.push({
        query: PostDocument,
        variables: { postId: targetId },
      });
    }
    if (likeData.isLiked) {
      const { data } = await unlikeTarget({ variables: { targetId, targetType }, refetchQueries });
      if (data?.unlikeTarget?.success) {
        setLikeData((d) => (d ? { ...d, isLiked: false, count: d.count - 1 } : null));
      }
      trackEvent('unliked', { targetType, object: trackingTarget });
    } else {
      const { data } = await likeTarget({ variables: { targetId, targetType }, refetchQueries });
      if (data?.likeTarget?.success) {
        setLikeData((d) => (d ? { ...d, isLiked: true, count: d.count + 1 } : null));
      }
      trackEvent('liked', { targetType, object: trackingTarget });
    }
    refetchReactionProfiles();
  };

  return (
    <div className={styles.mainBar}>
      <div className={styles.leftColumn}>
        <ReactionButton
          onClick={handleLikeClick}
          disabled={!isSignedIn || likeLoading || unlikeLoading}
          icon={<LikeIcon isLiked={likeData?.isLiked || false} />}
        >
          Like
        </ReactionButton>
        <ReactionButton onClick={handleCommentClick} icon={<CommentIcon />}>
          Comment
        </ReactionButton>
      </div>

      {shareButton !== null && <div className={styles.shareButton}>{shareButton}</div>}
      {optionsButton}
    </div>
  );
};
