import { ComponentProps } from 'react';
import styles from './MoreCommentsButton.module.scss';

type MoreCommentsButtonProps = ComponentProps<'button'>;
export const MoreCommentsButton = ({ ...rest }: MoreCommentsButtonProps): JSX.Element => {
  return (
    <button className={styles.more} {...rest}>
      Show more comments
    </button>
  );
};
