import styles from './LikeIcon.module.scss';

export const LikeIcon = ({ isLiked }: { isLiked: boolean }) => (
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.likeButton}
  >
    <mask id="like-button-mask" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 21C10 21 3 15 3 10C3 6.96243 5.46243 4 8.5 4C10.2434 4 11.6327 4.81119 12.5 6.07673C13.3673 4.81119 14.7566 4 16.5 4C19.5376 4 22 6.96243 22 10C22 15 15 21 12.5 21Z"
      />
    </mask>
    <path
      className={isLiked ? `${styles.innerLiked}` : `${styles.inner}`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 21C10 21 3 15 3 10C3 6.96243 5.46243 4 8.5 4C10.5165 4 11.8389 5.08522 12.5 6.7035C13.1611 5.08522 14.4835 4 16.5 4C19.5376 4 22 6.96243 22 10C22 15 15 21 12.5 21Z"
      fill="#e14d4d"
    />
    <path
      className={isLiked ? `${styles.outerLiked}` : `${styles.outer}`}
      d="M12.5 6.07673L11.1802 6.98123L12.5 8.90703L13.8198 6.98123L12.5 6.07673ZM12.5 19.4C12.5012 19.4 12.4896 19.4 12.4636 19.3966C12.4378 19.3932 12.4015 19.3868 12.354 19.3754C12.2574 19.3523 12.1305 19.3123 11.9729 19.2487C11.6552 19.1205 11.2644 18.9171 10.8193 18.6341C9.92913 18.0683 8.9155 17.2445 7.96063 16.2606C5.97345 14.2132 4.6 11.8538 4.6 10H1.4C1.4 13.1462 3.52655 16.2868 5.66437 18.4894C6.772 19.6305 7.97712 20.6192 9.10261 21.3347C9.66527 21.6923 10.2315 21.9967 10.7752 22.2161C11.2993 22.4277 11.8988 22.6 12.5 22.6V19.4ZM4.6 10C4.6 7.66289 6.51878 5.6 8.5 5.6V2.4C4.40608 2.4 1.4 6.26198 1.4 10H4.6ZM8.5 5.6C9.69911 5.6 10.6017 6.1371 11.1802 6.98123L13.8198 5.17224C12.6637 3.48528 10.7878 2.4 8.5 2.4V5.6ZM16.5 2.4C14.2122 2.4 12.3363 3.48529 11.1802 5.17224L13.8198 6.98123C14.3983 6.1371 15.3009 5.6 16.5 5.6V2.4ZM23.6 10C23.6 6.26198 20.5939 2.4 16.5 2.4V5.6C18.4812 5.6 20.4 7.66289 20.4 10L23.6 10ZM12.5 22.6C13.1012 22.6 13.7007 22.4277 14.2248 22.2161C14.7685 21.9967 15.3347 21.6923 15.8974 21.3347C17.0229 20.6192 18.228 19.6305 19.3356 18.4894C21.4735 16.2868 23.6 13.1462 23.6 10L20.4 10C20.4 11.8538 19.0265 14.2132 17.0394 16.2606C16.0845 17.2445 15.0709 18.0683 14.1807 18.6341C13.7356 18.9171 13.3448 19.1205 13.0271 19.2487C12.8695 19.3123 12.7426 19.3523 12.646 19.3754C12.5985 19.3868 12.5622 19.3932 12.5364 19.3966C12.5104 19.4 12.4988 19.4 12.5 19.4V22.6Z"
      fill="black"
      mask="url(#like-button-mask)"
    />
  </svg>
);
