import {
  ClubFeaturePolicy,
  ClubJoinPolicy,
  ClubMemberRole,
  useAmInvitedToClubQuery,
  useClubBehaviorQuery,
  useGetMyMembershipByClubIdQuery,
} from 'generated/graphql';
import { useMemo } from 'react';
import { useAuthContext } from './useAuth';

type Props = {
  clubId: string;
};

export function useClubBehavior({ clubId }: Props) {
  const { isSignedIn } = useAuthContext();

  const { data: myMembership } = useGetMyMembershipByClubIdQuery({
    variables: { clubId },
    skip: !isSignedIn,
  });
  const myRole = myMembership?.getMyMembershipByClubId?.role;

  const { data, loading: loadingBehavior } = useClubBehaviorQuery({
    variables: { clubId },
  });

  const { data: amInvited, loading: loadingAmInvited } = useAmInvitedToClubQuery({
    variables: { clubId },
    skip: !isSignedIn || (!!isSignedIn && !!myRole),
  });

  const clubBehavior = data?.clubBehavior;

  const canPostInClub = useMemo(() => {
    if (!clubId || !myRole || !clubBehavior) return false;
    return verifyCanDoInClub(clubBehavior.canPost, myRole);
  }, [clubId, myRole, clubBehavior]);

  const canSeeClub = useMemo(() => {
    if (!clubBehavior) return false;
    if (clubBehavior.visibility === 'Public') return true;
    if (!clubId || !myRole) return false;

    return !!myRole;
  }, [clubId, myRole, clubBehavior]);

  const canJoinClub = useMemo(() => {
    if (!clubId || !clubBehavior || myRole) return false;
    if (clubBehavior.canJoin === ClubJoinPolicy.RequiresApproval) {
      if (amInvited?.amInvitedToClub) return true;
      return false;
    } else {
      return true;
    }
  }, [clubId, myRole, clubBehavior, amInvited]);

  const canAddBook = useMemo(() => {
    if (!clubId || !myRole || !clubBehavior) return false;
    return verifyCanDoInClub(clubBehavior.canAddBook, myRole);
  }, [clubId, myRole, clubBehavior]);

  const canEditSettings = useMemo(() => {
    if (!clubId || !myRole) return false;
    if (myRole === ClubMemberRole.Owner) return true;
    return false;
  }, [clubId, myRole, clubBehavior]);

  const canDeletePosts = useMemo(() => {
    if (!clubId || !myRole) return false;
    if (myRole === ClubMemberRole.Owner) return true;
    return false;
  }, [clubId, myRole, clubBehavior]);

  return {
    canJoinClub,
    canPostInClub,
    canSeeClub,
    canAddBook,
    canEditSettings,
    canDeletePosts,
    clubBehavior: data?.clubBehavior,
    loading: loadingBehavior || loadingAmInvited,
  };
}

function verifyCanDoInClub(policy: ClubFeaturePolicy, membership: ClubMemberRole) {
  switch (policy) {
    case 'MemberOrHigher':
      return isMemberOrHigher(membership);
    case 'ModeratorOrHigher':
      return isModeratorOrHigher(membership);
    case 'OwnerOrHigher':
      return isOwnerOrHigher(membership);
    default:
      return false;
  }
}

function isMemberOrHigher(role: ClubMemberRole) {
  return role === ClubMemberRole.Member || role === ClubMemberRole.Moderator || role === ClubMemberRole.Owner;
}

function isModeratorOrHigher(role: ClubMemberRole) {
  return role === ClubMemberRole.Moderator || role === ClubMemberRole.Owner;
}

function isOwnerOrHigher(role: ClubMemberRole) {
  return role === ClubMemberRole.Owner;
}
