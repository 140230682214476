import DataLoader from 'dataloader';
import {
  ClubStatsDocument,
  ClubStatsPartsFragment,
  ClubStatsQuery,
  ClubStatsQueryVariables,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';
import { emitter, Payload } from './events';

export const clubStatsLoader = new DataLoader(
  async (keys: readonly string[]): Promise<ClubStatsPartsFragment[]> => {
    const client = getApolloClient();

    const { data } = await client.query<ClubStatsQuery, ClubStatsQueryVariables>({
      query: ClubStatsDocument,
      variables: { clubIds: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.clubsStats;
  },
  { batch: true, cache: true }
);

export function useClubStatsLoader(clubId?: string | null) {
  const [data, setData] = useState<ClubStatsPartsFragment>();

  useEffect(() => {
    load();
    function applyNewData({ key, value }: Payload<ClubStatsPartsFragment>) {
      if (key === clubId) setData(value);
    }
    emitter.on('clubStats', applyNewData);
    return () => emitter.off('clubStats', applyNewData);
  }, [clubId]);

  async function load() {
    if (!clubId) return;
    const newVal = await clubStatsLoader.load(clubId);
    emitter.emit('clubStats', { key: clubId, value: newVal });
  }

  async function refetch() {
    if (!clubId) return;
    clubStatsLoader.clear(clubId);
    await load();
  }

  return { data, setData, refetch };
}
