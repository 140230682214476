import { ReactNode } from 'react';
import styles from './CommentsContainer.module.scss';

type Props = {
  children: ReactNode;
};

export const CommentsContainer = ({ children }: Props): JSX.Element => {
  return <div className={styles.container}>{children}</div>;
};
