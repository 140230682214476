import classNames from 'classnames';
import { ClubMemberRole } from 'generated/graphql';
import { CrownIcon } from 'ui/icons';
import styles from './ClubModeratorSticker.module.scss';

export type ClubModStickerProps = {
  role?: ClubMemberRole;
  small?: boolean;
};

const ClubModeratorSticker = ({ role, small }: ClubModStickerProps): JSX.Element | null => {
  if (!role || role === 'Member') return null;
  else
    return (
      <span
        className={classNames(styles.modSticker, {
          [styles.moderator]: role === 'Moderator',
          [styles.small]: small,
        })}
      >
        <CrownIcon />
      </span>
    );
};

export default ClubModeratorSticker;
