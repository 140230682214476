import { Link } from '@tiptap/extension-link';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TextLinkPreview } from 'ui/specific/linkPreview/TextLinkPreview';
import { CustomMentionViewer } from './MentionExtension';
import { RichTextError } from './RichTextError';
import styles from './RichTextViewer.module.scss';

type Props = {
  content: Content;
  hideLinkPreview?: boolean;
};

export const RichTextViewer = (props: Props): JSX.Element => {
  return (
    <ErrorBoundary FallbackComponent={RichTextError}>
      <RichTextViewerInner {...props} />
    </ErrorBoundary>
  );
};

const RichTextViewerInner = ({ content, hideLinkPreview }: Props): JSX.Element | null => {
  const editor = useEditor({
    parseOptions: {
      preserveWhitespace: 'full',
    },
    extensions: [
      StarterKit,
      Link.configure({
        HTMLAttributes: {
          class: styles.link,
        },
      }),
      CustomMentionViewer.configure({
        HTMLAttributes: {
          class: styles.mention,
        },
      }),
    ],
    editable: false,
    content: content,
  });

  // if viewer is live and content changes (someone edits their review, highlight, etc in a modal)
  // the editor sets content with with new, edited content
  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(content);
  }, [content]);

  if (!editor) return null;

  return (
    <div className={styles.container}>
      <EditorContent editor={editor} />
      {hideLinkPreview !== undefined && (
        <TextLinkPreview text={editor.getText()} state={{ hideLinkPreview }} />
      )}
    </div>
  );
};
