import classNames from 'classnames';
import { RichTextData } from 'containers/ReactionBar/Comments';
import { ProfilePartsFragment } from 'generated/graphql';
import { useEffect, useRef, useState } from 'react';
import { AvatarLink, Button } from 'ui/generic';
import { MentionSuggestionType, RichTextEditor } from 'ui/specific/richText/RichTextEditor';
import styles from './CommentInput.module.scss';

type InputProps = {
  profile: ProfilePartsFragment;
  onSubmit: (data: { text: string; json: Record<string, unknown> }) => Promise<void>;
  getSuggestions: (query: string) => Promise<MentionSuggestionType>;
  isLoading?: boolean;
  isReply?: boolean;
  autoFocus?: boolean;
  replyMentions?: ProfilePartsFragment[];
};

export const CommentInput = ({
  profile,
  onSubmit,
  getSuggestions,
  isLoading,
  isReply,
  autoFocus,
  replyMentions = [],
}: InputProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [commentData, setCommentData] = useState<RichTextData | null>(null);

  useEffect(() => {
    if (autoFocus) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, []);

  return (
    <div ref={ref} className={classNames(styles.inner, { [styles.isReply]: isReply })}>
      <div className={styles.input}>
        <RichTextEditor
          getSuggestions={getSuggestions}
          placeholder="Share your thoughts..."
          setValue={(value: RichTextData) => setCommentData(value)}
          submitted={isLoading}
          avatar={<AvatarLink profile={profile} width={30} />}
          autoFocus={autoFocus}
          replyMentions={replyMentions}
        />

        {commentData && (
          <div className={styles.sendButton}>
            <Button
              type="submit"
              variant="focus"
              mini={isReply}
              disabled={isLoading}
              onClick={async () => {
                await onSubmit({ text: commentData.text, json: commentData.textJson });
              }}
            >
              Post
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
